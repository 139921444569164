import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Container, Flex, Link } from "@chakra-ui/react"
import React from "react"
import Header from "./header"

export interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <Flex minH="100vh" direction="column">
      <Header />
      <Container flexGrow={1} m="auto" maxW="container.md" px="6" py="5">
        <Box as="main">{children}</Box>
      </Container>
      <Box as="footer" p={4} m="0 auto">
        © {new Date().getFullYear()}, Built with
        {` `}
        <Link href="https://www.gatsbyjs.org" color="teal.500" isExternal>
          Gatsby <ExternalLinkIcon name="external-link" mx="2px" />
        </Link>
      </Box>
    </Flex>
  )
}

export default Layout
