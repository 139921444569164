import { MoonIcon, SunIcon } from "@chakra-ui/icons"
import {
  Box,
  IconButton,
  Stack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const iconColor = useColorModeValue("teal.300", "teal.600")

  return (
    <Box as="header" mb="6">
      <Box m="0 auto" maxW="960" px="6" py="5">
        <Stack justify="flex-end" align="center" flexDirection="row">
          <IconButton
            _focus={{ outline: "none" }}
            variantColor="link"
            color={iconColor}
            aria-label="Toggle color mode"
            size="lg"
            icon={colorMode === "dark" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default Header
